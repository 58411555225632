import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import Feed from '../components/Feed';
import Page from '../components/Page';
import Pagination from '../components/Pagination';

const CategoryTemplate = ({ data, pageContext }) => {
  const {
    title: siteTitle,
    subtitle: siteSubtitle
  } = data.site.siteMetadata;

  const {
    category,
    currentPage,
    prevPagePath,
    nextPagePath,
    hasPrevPage,
    hasNextPage,
  } = pageContext;

  // New source for Contentful
  // const { edges } = data.allMarkdownRemark;
  const { edges } = data.allContentfulBlogPost;

  const pageTitle = currentPage > 0 ? `${category} - Page ${currentPage} - ${siteTitle}` : `${category} - ${siteTitle}`;

  return (
    <Layout title={pageTitle} description={siteSubtitle}>
      <Sidebar />
      <Page title={category}>
        <Feed edges={edges} />
        <Pagination
          prevPagePath={prevPagePath}
          nextPagePath={nextPagePath}
          hasPrevPage={hasPrevPage}
          hasNextPage={hasNextPage}
        />
      </Page>
    </Layout>
  );
};

// export const query = graphql`
//   query CategoryPage($category: String, $postsLimit: Int!, $postsOffset: Int!) {
//     site {
//       siteMetadata {
//         title
//         subtitle
//       }
//     }
//     allMarkdownRemark(
//         limit: $postsLimit,
//         skip: $postsOffset,
//         filter: { frontmatter: { category: { eq: $category }, template: { eq: "post" }, draft: { ne: true } } },
//         sort: { order: DESC, fields: [frontmatter___date] }
//       ){
//       edges {
//         node {
//           fields {
//             categorySlug
//             slug
//           }
//           frontmatter {
//             date
//             description
//             category
//             title
//           }
//         }
//       }
//     }
//   }
// `;

// Contentful query, still need categorySlug. I think the create-pages and on-create-node has to do this
export const query = graphql`
query CategoryPage($category: String, $postsLimit: Int!, $postsOffset: Int!) {
  site {
    siteMetadata {
      title
      subtitle
    }
  }
  allContentfulBlogPost(limit: $postsLimit, skip: $postsOffset, filter: {category: {eq: $category}}, sort: {order: DESC, fields: [publishDate]}) {
    edges {
      node {
        category
        publishDate
        description {
          description
        }
        title
        slug
        fields {
          categorySlug
        }
      }
    }
  }
}
`;

export default CategoryTemplate;
